<!--
 * @Author: your name
 * @Date: 2021-03-04 18:38:56
 * @LastEditTime: 2023-11-01 14:18:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\components\patient\EditPatient.vue
-->
<template>
  <div class="patient">
    <van-form
      ref="form"
      label-width="110"
      class="inform"
      input-align="right"
      error-message-align="right"
      :show-error="false"
      @submit="onSubmit"
    >
      <van-field
        :value="relationValue"
        readonly
        name="relationName"
        is-link
        label="与本人关系 "
        placeholder="请选择与本人关系"
        @click="Picker = true"
      />
      <van-popup v-model="Picker" position="bottom">
        <van-picker
          show-toolbar
          :columns="relationList"
          value-key="value"
          @confirm="onRelation"
          @cancel="Picker = false"
        />
      </van-popup>
      <van-field
        v-model="name"
        type="text"
        name="name"
        label="姓名"
        placeholder="请输入姓名"
        maxlength="10"
        :rules="[{ required: true,message:'请输入姓名'}]"
      />
      <van-field
        v-model="moblie"
        required
        name="moblie"
        label="手机号"
        placeholder="请输入手机号"
        maxlength="11"
        :rules="[{ required: true, message: '请填写手机号' }, {phoneValidator, message: '手机号格式不正确'},{trigger :'onChange'}]"
      />
      <van-field
        v-model="idCard"
        type="text"
        name="idCard"
        label="身份证号"
        placeholder="请输入身份证号"
        :maxlength="18"
        :rules="[{ required: true,message:'请输入身份证号'},{ validator, message: '请输入正确的身份证号' }]"
        @blur="idCardChange"
      />
      <van-field name="sex" label="性别">
        <template #input>
          <van-radio-group v-model="sex" direction="horizontal">
            <van-radio name="1">
              男
            </van-radio>
            <van-radio name="2">
              女
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        is-link
        label="出生日期"
        name="birthday"
        :value="birthday"
        :rules="[{ required: true, message: '请选择出生日期' }]"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :max-date="maxDate"
          :min-date="minDate"
          :formatter="formatter"
          @confirm="confirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <AddressItem
        :address-picker="addressPicker"
        :dpmc="form.dpmc"
        :dcmc="form.dcmc"
        :ddmc="form.ddmc"
        :address="address"
        :rule="[{ required: true, message: '请选择地址' }]" @addressChange="addressChange"
      />
      <van-field name="isDefaulted" label="设为默认就诊人">
        <template #input>
          <van-switch v-model="isDefaulted" size="20" :disabled="changeIsDEfaulted" inactive-color="#DDDDDD" active-color="#1C9CF6" />
        </template>
      </van-field>
      <div class="line" />
      <div class="info-items">
        <span class="info-item-title">绑定就诊人卡</span>
      </div>
      <van-cell title="就诊卡类型">
        门诊号
      </van-cell>
      <van-field
        v-model="second"
        type="text"
        name="second"
        label="卡号"
        :readonly="isAdd"
        placeholder="请输入卡号"
      />
      <div v-if="!isAdd" class="footBtn">
        <van-button
          round
          size="small"
          type="info"
          class="btn magTop"
          native-type="submit"
          color="#3A8AE5"
        >
          提交
        </van-button>
      </div>
      <div v-else class="footBtn bottom">
        <van-button
          round
          size="small"
          type="info"
          class="btn magTop"
          native-type="submit"
          color="#1989fa"
        >
          保存
        </van-button>
        <van-button
          round
          size="small"
          type="info"
          class="btn"
          color="#DC3030"
          native-type="button"
          @click.stop="del"
        >
          删除
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
  import { mapGetters,mapMutations} from 'vuex'
  import {formatTime,getBirth} from '../../utils/cache'
  import {CONSTANT} from '../../utils/constant'
  import AddressItem from '../../components/category/addressItem'
  import {isIdCardNum} from '../../utils/check'
  import { isPhoneNum } from '@/utils/index'
  import * as types from '../../store/mutation-types'
  import {patientModel} from '../../api/patient'
  import {SET_HEADER_CONFIG} from '../../store/mutation-types'
  const keys = ['p', 'c', 'd']
  const ADD_ID = '-1'
  export default {
    components:{
      AddressItem
    },
    data(){
      return{
       address: '',
       userRelation: '',
       addressPicker:false,
       name:'',
       moblie:'',
       idCard:"",
       sex:'1',
       birthday:"",
       isDefaulted: false,
       changeIsDEfaulted:false,
       Picker:false,
       showPicker:false,
       minDate: new Date(1930, 0, 1),
       maxDate: new Date(),
       currentDate: new Date(),
       relationValue:'',
       relationKey:'',
       isAdd:false,
      // 省代码
        dpdm: '',
        // 省名称
        dpmc: '',
        // 市代码
        dcdm: '',
        dcmc: '',
        // 县区代码
        dddm: '',
        ddmc: '',
        first:'',
        second:'',
        arrList:[],
        form: {
          dpdm: '',
          // 省名称
          dpmc: '',
          // 市代码
          dcdm: '',
          dcmc: '',
          // 县区代码
          dddm: '',
          ddmc: ''
        }
      }
    },
    computed: {
      ...mapGetters({relationList:'patient/relationList'})
    },
    created() {
      // console.log(this.$route.params.id,"id")
      if(this.$route.params.id===ADD_ID){
        this.$store.commit(`header/${SET_HEADER_CONFIG}`, { title:'新增就诊人'})
      }
    },
    async mounted(){
      await this.$store.dispatch('patient/getRelation')
      const isAdd = this.$route.params.id === ADD_ID
      if (!isAdd) {
        this.isAdd=true
        const result= await patientModel.getPatientListDetail({id:this.$route.params.id})
        if(result&&result.data){
          const data=result.data
          this.relationValue=data.jtgx
          this.relationKey=data.relatives
          this.name=data.patxm
          this.moblie=data.mobile
          this.idCard=data.zjh
          this.sex=data.gender
          this.id=data.id
          this.birthday=data.csrq.substr(0,10)
          this.isDefaulted=data.isdefault==='1'?true:false
          this.changeIsDEfaulted=data.isdefault==='1'?true:false
          this.form.dpmc=data.patpmc
          this.form.dpdm=data.patpdm
          this.form.ddmc=data.patdmc
          this.form.dddm=data.patddm
          this.form.dcmc=data.patcmc
          this.form.dcdm=data.patcdm
          this.address = data.patpmc +' '+ data.patcmc+ ' ' + data.patdmc
          if (data.visitcardinfos) {
             data.visitcardinfos.map((v) => {
               if (v.jzklxmc == '医保卡') {
                   this.first = v.jzkh
                } else {
                   this.second = v.jzkh
                }
             })
          }else{
             return false
          }
        }
      }
    },
    methods:{
        idCardChange(){
          this.$refs.form.validate('idCard').then(() => {
            this.birthday=getBirth(this.idCard)
            this.currentDate=new Date(getBirth(this.idCard))
          }).catch(() => {
          })
        },
        async del(){
          this.$dialog.confirm({
            message: '确定要删除吗？'
          }).then(async () => {
           const {code}= await patientModel.delete({id:this.$route.params.id})
            this.handleSuccess(code, '删除失败')
          })
        },
        handleSuccess(code,message){
          if (code === CONSTANT.SUCCESS_CODE) {
            this.$router.back()
          } else {
            this.$toast(message)
          }
        },
        onSubmit(value){
          if(!value.second){
             this.$toast("请输入门诊卡号")
             return false
          }
          this.arrList=[]
          let patientRequest={
            age: '',
            createUserId: '',
            csrq: value.birthday, //出生日期
            gender: value.sex, //性别
            hyzk: '',
            id:this.isAdd?this.id:'',
            isdefault: value.isDefaulted?"1":'0', //默认
            mobile: value.moblie,
            mz: '',
            patcdm: this.form.dcdm,
            patcmc: this.form.dcmc,
            patddm: this.form.dddm,
            patdmc: this.form.ddmc,
            patpdm: this.form.dpdm,
            patpmc: this.form.dpmc,
            patdm: '',
            relatives:this.relationKey,
            patxm: value.name, //患者名称
            txurl: '',
            updateUserId: '',
            wxuserid: '',
            xxdz: '',
            zjh: value.idCard,
            zjlx: '01'
          }
          if(value.second){
            this.arrList.push({
                jzkh: value.second,
                jzklx: '2',
                jzklxmc: '门诊号'
            })
          }
          const methodName = this.isAdd ? 'edit' : 'add'
          patientModel[methodName]({patientRequest,visitcardinfos:this.arrList}).then(res=>{
            if(res.code===CONSTANT.SUCCESS_CODE){
              this.$router.back()
            }
          }).catch(eorr=>{
            this.$toast(eorr.msg)
          })
        },
        confirm(val) {
          val = formatTime(val)
          this.birthday = val
          this.showPicker = false
        },
        add(){
          this.addList()
        },
        formatter(type, val) {
          if (type === 'year') {
            return val + '年'
          }
          if (type === 'month') {
            return val + '月'
          }
          if (type === 'day') {
            return val + '日'
          }
          return val
        },
        onRelation(val){
          this.relationValue=val.value
          this.relationKey=val.key
          this.Picker=false
        },
        validator(val) {
          return !isIdCardNum(val)
        },
        phoneValidator(val){
          return isPhoneNum(val)
        },
        addressChange(value){
          this.dpmc=value[0].name
          keys.forEach((key, index) => {
            this.form[`d${ key }dm`] = value[index].code
            this.form[`d${ key }mc`] = value[index].name
          })
          if(this.form.dpmc && this.form.dcmc && this.form.ddmc){
            this.address = this.form.dpmc +' '+ this.form.dcmc+ ' ' + this.form.ddmc
          }else{
            this.address = '' // 地址没选全
          }
        },
        ...mapMutations({
          addList: `patient/${types.ADD_LIST}`
        })
      }
  }
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.patient{
  .inform{
    height: 90%;
    overflow: scroll;
  }
  .form-label {
    line-height: 75px;
    font-size: 32px;
    font-weight: bold;
    padding-left: 15px;
    color: #373e55;
  }
  .textarea-sty {
    display: block;
    color: #373e55;
    font-size: 30px;
    line-height: 40px !important;
  }
  .tip-txt {
    color: #b2b9bf;
    font-size: 26px;
    font-weight: 500;
    margin-top: 20px;
    margin-left: 20px;
  }
  .footBtn {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 96px;
    line-height: 75px;
    text-align: center;
    background-color: @white-color;
    .btn {
      width: 90%;
      height: 68px;
    }
    .magTop {
      margin-bottom: 24px;
    }
  }
  .bottom {
    bottom: 100px;
  }
  .line{
    width:100%;
    background: #f5f5f5;
    height: 16px;
  }
  .info-items {
    display: flex;
    align-items: center;
    padding: 20px 32px 0px 32px;

    font-size: 28px;
    color: #3d3d3d;
    .info-item-title {
      font-weight: bold;
      font-size: 32px;
    }

  }
  .info-item {
    display: flex;
    align-items: center;
    padding: 20px 32px;
    line-height: 40px;
    font-size: 28px;
    color: #3d3d3d;
    .info-item-title {
      font-weight: bold;
      font-size: 32px;
    }

  }
}

</style>
