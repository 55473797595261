<!--
 * @Author: your name
 * @Date: 2021-03-04 18:22:15
 * @LastEditTime: 2021-03-04 19:05:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\patient\from.vue
-->
<template>
  <edit-patient jump :type="$route.query.flag" />
</template>
<script>
import EditPatient from '../../components/patient/EditPatient'
export default {
  components:{
    EditPatient
  }
}
</script>
<style scoped lang='less'>

</style>
