/*
 * @Author: your name
 * @Date: 2021-03-08 16:00:03
 * @LastEditTime: 2021-03-08 16:03:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\utils\check.js
 */
import {CONSTANT}from './constant'
import {formatTime} from './cache'
const AREA_MAP = {
  11: '北京',
  12: '天津',
  13: '河北',
  14: '山西',
  15: '内蒙古',
  21: '辽宁',
  22: '吉林',
  23: '黑龙江',
  31: '上海',
  32: '江苏',
  33: '浙江',
  34: '安徽',
  35: '福建',
  36: '江西',
  37: '山东',
  41: '河南',
  42: '湖北',
  43: '湖南',
  44: '广东',
  45: '广西',
  46: '海南',
  50: '重庆',
  51: '四川',
  52: '贵州',
  53: '云南',
  54: '西藏',
  61: '陕西',
  62: '甘肃',
  63: '青海',
  64: '宁夏',
  65: '新疆',
  71: '台湾',
  81: '香港',
  82: '澳门',
  91: '国外'
}
const WEIGHT_LIST = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
const LAST_CHAR_LIST = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

export function isIdCardNum(idNum, required = true) {

  if (!idNum && required) {
    return '身份证不能为空'
  }

  if (typeof idNum === 'number') {
    idNum = String(idNum)
  }

  if (!CONSTANT.ID_CARD_REG.test(idNum)) {
    return '身份证格式不正确'
  }

  // 地区校验
  if (!AREA_MAP[idNum.substr(0, 2)]) {
    return '非法的身份证地区'
  }

  // 最后一位校验，数字或字母x
  let sum = 0
  for (let i = 0; i < idNum.length - 1; i++) {
    sum += idNum[i] * WEIGHT_LIST[i]
  }
  // 计算最后一位身份证号码
  const lastChar = LAST_CHAR_LIST[sum % 11]
  if (idNum[idNum.length - 1] !== lastChar) {
    return '非法的身份证号'
  }

  // 出生日期验证
  const birthdayStr = `${idNum.substr(6, 4)}/${idNum.substr(10, 2)}/${idNum.substr(12, 2)}`
  const birthday = formatTime(new Date(birthdayStr), 'yyyy/MM/dd')
  if (birthdayStr !== birthday) {
    return '非法的身份证出生日期'
  }

  return ''
}
